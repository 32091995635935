export default {
  privacyLink: '',
  agbLink: '',
  requestUrl: 'https://api.elements-dev.de/api/extern/b2c/configurator/request',
  leadUrl: 'https://api.elements-dev.de/api/extern/b2c/configurator/lead',
  sendCustomerMailUrl: 'test',
  afterFormSuccessUrl: '',
  colors: {
    'primary': '#757575',
    'secondary': '#F2BA40',
    'background': '#F2F2F2',
    'highlight': '#FF0000',
    'box': '#EEEEEE',
    'font': '#333333',
  },
  contact: {
    name: 'Max Mustermann',
    email: 'max.mustermann@test.de',
    phone: '+49 123456776',
    company: 'Muster Sanitär',
    image: './images/senior_consultant.jpg',
  },
  pages: [
    {
      title: 'Sind Sie Eigentümer oder Mieter?',
      questions: [
        { text: 'Eigentümer', icon: './page_icons/eigentuemer.svg', answer: 'owner' },
        { text: 'Mieter', icon: './page_icons/mieter.svg', answer: 'tenant' },
      ],
    },
    {
      title: 'Planen Sie eine Badezimmer- sanierung oder einen Neubau?',
      questions: [
        { text: 'Sanierung', icon: './page_icons/sanierung.svg', answer: 'reconstruction' },
        { text: 'Neubau', icon: './page_icons/neubau.svg', answer: 'new' },
      ],
    },
    {
      title: 'Wieviele Personen leben im Haushalt?',
      questions: [
        { text: '1 Person', icon: './page_icons/1person.svg', answer: '1' },
        { text: '2 Personen', icon: './page_icons/2personen.svg', answer: '2' },
        { text: '3 Personen', icon: './page_icons/3personen.svg', answer: '3' },
        { text: '4 Personen', icon: './page_icons/4personen.svg', answer: '4' },
      ],
      infoBoxText:
        'Bitte geben Sie die Gesamtzahl aller Personen im Haushalt ein. Mit dieser Angabe können wir bereits notwendiges Zubehör berücksichtigen und die Inneneinrichtung optimieren.',
    },
    {
      title: 'In welcher Qualität soll Ihr Bad ausgestattet werden?',
      questions: [
        { text: 'Basic', icon: './page_icons/basic.svg', answer: 'basic' },
        { text: 'Komfort', icon: './page_icons/komfort.svg', answer: 'comfort' },
        { text: 'Luxus', icon: './page_icons/luxus.svg', answer: 'luxus' },
      ],
      infoBoxText: `
    Basis:  
    Einfache Ausstattung, Armaturen und Fliesen. 
    Komfort:
    Der Anspruch an das eigene Bad. Durchdachte und bewährte Lösungen.
    Design/Luxus:
    Ihr Haus hat Stil und Charakter? Dann gilt dies auch für Ihr Badezimmer.`,
    },
    {
      title: 'Wie groß ist die Grundfläche Ihres Bades?',
      questions: [
        { text: '4-5 qm', icon: './page_icons/4-5.svg', answer: '4-5' },
        { text: '5-7 qm', icon: './page_icons/5-7.svg', answer: '5-7' },
        { text: '7-10 qm', icon: './page_icons/7-10.svg', answer: '7-10' },
        { text: '>10 qm', icon: './page_icons/7-10.svg', answer: '10-16' },
      ],
      infoBoxText: `4-5qm: Ihr Bad hat die Beispiel Badmaße L*B von 1,80x2,50m
    5-7qm: L*B 2x3m
    7-10qm: L*B 3x3m
    10-15qm: L*B 3x4m`,
    },
    {
      title: 'Möchten Sie Ihr Badezimmer mit einer Dusche und/oder einer Badewanne ausstatten?',
      questions: [
        { text: 'Dusche', icon: './page_icons/dusche.svg', answer: 'shower' },
        { text: 'Badewanne', icon: './page_icons/badewanne.svg', answer: 'bathtub' },
        {
          text: 'Dusche und Badewanne',
          icon: './page_icons/dusche_badewanne.svg',
          answer: 'both',
        },
      ],
      infoBoxText:
        'Darf es eine große, barrierefreie Dusche sein oder bevorzugen Sie eher eine Wanne? Oder fällt Ihre Wahl auf beides?',
    },
    {
      title: 'Zeitpunkt der Ausführung?',
      questions: [
        { text: '1-3 Monate', icon: './page_icons/1-3month.svg', answer: '1-3' },
        { text: '3-6 Monate', icon: './page_icons/3-6month.svg', answer: '3-6' },
        { text: 'Später', icon: './page_icons/later.svg', answer: 'later' },
      ],
      infoBoxText:
        'Wann planen Sie Ihr Badprojekt? Mit dieser Information helfen Sie uns bei der Planung unserer Ressourcen!',
    },
  ],
};
