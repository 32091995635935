import React from 'react';
import {priceFormatter} from '../../helper';

export const SummaryTable = ({surveryResponseAnswers, dispatch}) => {

  if (!surveryResponseAnswers.cart) {
    return;
  }

  const content = surveryResponseAnswers.cart.content.map((row, index) => {
    return (
        <div className="col-md-6 col-12 summaryTableRow" key={index}>
          <div style={{paddingRight: 5}}>{row.name?.split("/")?.join(" / ")}</div>
          <div>{priceFormatter.format(row.price_calc_tax)}</div>
        </div>
    );
  });

  return (
      <div className="summaryTable">
        <h3>Preisinformation</h3>
        <hr/>
        <p>
            Für die von Ihnen gewünschte Badausstattung können Sie ungefähr folgende Kosten einplanen. In der Regel setzt sich der Gesamtpreis für ein neues Badezimmer zu einem Drittel aus Material- und zu zwei Dritteln aus den Arbeitskosten zusammen.
            <br/><br/>Nehmen Sie gerne über den untenstehenden Link Kontakt zu uns auf, damit wir in einem persönlichen Gespräch weitere Fragen klären können!
        </p>
        <hr/>
        <div className="summaryTableContent">{content}</div>
        <hr/>
        <p className="summaryTableSum">
          Summe Total (mit
          MwSt.):
          <strong>
            {' ' + priceFormatter.format(
                surveryResponseAnswers.cart.info.price_calc_tax)}
          </strong>
        </p>

        <div className="buttonWrapper">
          <button
              onClick={() => {
                dispatch({type: 'GO_TO_FORM'});
              }}
              className="button col-xs-12 col-sm-3 col-md-3"
          >
            Zum Kontaktformular
          </button>
        </div>
      </div>
  );
};
