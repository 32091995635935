export const initialState = {
    currentPageIndex: 0,
    lastPageIndex: 0,
    responseArray: [],
    pending: false,
    leadPending: false,
    leadError: false,
    error: false,
    success: false,
    surveryResponseAnswers: {},
    showForm: false,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_CURRENT_PAGE_INDEX':
            return {
                ...state,
                lastPageIndex: state.currentPageIndex,
                currentPageIndex: action.payload,
            };
        case 'SET_RESPONSE_ARRAY':
            return {
                ...state,
                responseArray: action.payload,
            };
        case 'SEND_SURVEY_PENDING':
            return {
                ...state,
                pending: true,
                error: false,
                lastPageIndex: state.currentPageIndex,
                currentPageIndex: state.currentPageIndex + 1,
            };
        case 'SEND_SURVEY_ERROR':
            return {
                ...state,
                pending: false,
                error: true,
            };
        case 'SEND_SURVEY_SUCCESS':
            return {
                ...state,
                pending: false,
                error: false,
                success: true,
                surveryResponseAnswers: action.payload,
            };
        case 'SEND_LEAD_PENDING':
            return {
                ...state,
                leadPending: true
            }
        case 'SEND_LEAD_ERROR':
            return {
                ...state,
                leadPending: false,
                leadError: true
            }
        case 'SEND_LEAD_SUCCESS':
            return {
                ...state,
                leadPending: false
            }
        case 'GO_TO_FORM': {
            return {
                ...state,
                showForm: true,
                lastPageIndex: state.currentPageIndex,
                currentPageIndex: state.currentPageIndex + 1,
            };
        }
        case 'GO_BACK_FORM':
            return {
                ...state,
                currentPageIndex: action.payload,
                showForm: false,
            };
        case 'GO_BACK_TABLE':
            return {
                ...state,
                lastPageIndex: state.currentPageIndex,
                currentPageIndex: action.payload,
                success: false,
            };
    }
};
