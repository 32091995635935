import React from 'react';
import SVG from 'react-inlinesvg';

export const Question = ({id, icon, text, selectAnswer, isSelected}) => {
  return (
      <div className={isSelected ? 'paper questionSelected embed-responsive-item' : 'paper embed-responsive-item'}
           onClick={() => selectAnswer(id)} id={id}>
        <SVG src={icon}/>

        <span className="description">{text}</span>
      </div>
  );
};
