import React from 'react';
import {Question} from '../Question';

export const QuestionsPage = ({questions, title, infoBoxText, selectAnswer, selectedQuestionFromPage}) => {
  const renderInfoBox = (text) => {
    return (
        <div
            className="infoBox fa fa-info-circle fa-lg inner-element bubble-element clickable-element">
          <div className="popup">
            <span className="description">{text}</span>
          </div>
        </div>
    );
  };

  const renderQuestions = () => {
    if (!questions || !questions.length) {
      return;
    }

    return questions.map((question, index) => {
      return (
          <div className="col-lg-3 col-md-4 col-6" key={index}>
            <div className='embed-responsive embed-responsive-1by1 paper-item-wrapper'>
              <Question
                  text={question.text}
                  icon={question.icon}
                  selectAnswer={selectAnswer}
                  id={index}
                  isSelected={question.answer === selectedQuestionFromPage}
              />
            </div>
          </div>
      );
    });
  };

  return (
    <div className="page">
      <div className="pageHeader">
        <span className="title">{title}</span>
        {infoBoxText && renderInfoBox(infoBoxText)}
      </div>
      <div className="container">
        <div className="row flexCenter">{renderQuestions()}</div>
      </div>
    </div>
  );
};
