import React from 'react';

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: 'DEFAULT',
      firstName: '',
      lastName: '',
      email: '',
      street: '',
      streetNumber: '',
      zipCode: '',
      city: '',
      phone: '',
      isInvalidGender: false,
      isInvalidEmail: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;
    // also validate fields here
    if (this.state.gender === 'DEFAULT') {
      this.setState({ isInvalidGender: true });
      hasError = true;
    }
    if(!validateEmail(this.state.email)){
      this.setState({ isInvalidEmail: true });
      hasError = true;
    }
    if(!hasError) {
      const contact = {
        salutation: this.state.gender,
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        email: this.state.email,
        street: this.state.street,
        street_no: this.state.streetNumber,
        zip_code: this.state.zipCode,
        city: this.state.city,
        phone: this.state.phone,
      };

      this.props.handleSubmitForm(contact);
    }
  };

  handleChange = (e) => {
    const value = e.target.value;
    const newState = {
      ...this.state,
      [e.target.name]: value
    }
    if(e.target.name === "email"){
     newState.isInvalidEmail = false;
    }
    this.setState(newState);
  };

  handleChangeGender = (e) => {
    const value = e.target.value;

    this.setState({ ...this.state, [e.target.name]: value, isInvalidGender: false });
  };

  render() {
    const { contact, leadPending, leadError } = this.props;
    return (
      <>
        {contact && <div className="contact">
          <div className={'content flexCenter'}>
            <img alt={contact.name} src={contact.image}
                 className={'avatar'} />
            <div>
              <div className={'header'}>{contact.name}</div>
              <div className={'description'}>{contact.company}</div>
              <div className={'description'}>
                <div>{contact.phone}</div>
                <div>{contact.email}</div>
                <div className={'line'} />
              </div>
            </div>
          </div>
        </div>
        }
        <form className="form-group" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="row">
                <div className="col-4">
                  <select
                    className={this.state.isInvalidGender ? 'form-control is-invalid' : 'form-control'}
                    id="exampleFormControlSelect1"
                    onChange={this.handleChangeGender}
                    value={this.state.gender}
                    name="gender"
                    required
                  >
                    <option value="DEFAULT" disabled hidden>
                      Keiner
                    </option>
                    <option value="herr">Herr</option>
                    <option value="frau">Frau</option>
                    <option value="none">Keine Angabe</option>
                  </select>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Vorname"
                    value={this.state.firstName}
                    name="firstName"
                    required
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Nachname"
                value={this.state.lastName}
                name="lastName"
                required
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6 col-12">
              <input
                type="email"
                className={this.state.isInvalidEmail ? 'form-control is-invalid' : 'form-control'}
                aria-describedby="emailHelp"
                placeholder="E-mail"
                value={this.state.email}
                name="email"
                required
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6 col-12">
              <input
                type="number"
                className="form-control"
                placeholder="PLZ"
                value={this.state.zipCode}
                name="zipCode"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Stadt"
                value={this.state.city}
                name="city"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Straße"
                value={this.state.street}
                name="street"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Hausnummer"
                value={this.state.streetNumber}
                name="streetNumber"
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-6 col-12">
              <input
                type="tel"
                className="form-control"
                placeholder="Telefonnummer"
                value={this.state.phone}
                name="phone"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6 col-12">
              <div className="col-12">
                <label className="b-contain">
                  <span>Ja, ich habe die <a href={this.props.privacyLink}
                                            target="_blank">Datenschutzerklärung </a> zur Kenntnis genommen.</span>
                  <input type="checkbox" required />
                  <div className="b-input"></div>
                </label>
              </div>

              <div className="col-12">
                <label className="b-contain">
                  <span>Ich erkläre mich mit den <a href={this.props.agbLink}
                                                    target="_blank">AGB</a> einverstanden.</span>
                  <input type="checkbox" required />
                  <div className="b-input"></div>
                </label>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <button type="submit" disabled={leadPending} className="button col-12">
                Senden
              </button>
            </div>
            {leadError && "Es ist ein Fehler aufgetreten"}
          </div>
        </form>
      </>
    );
  }
}
