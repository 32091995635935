import React, {useReducer, useEffect, useState} from 'react';
import 'url-search-params-polyfill'; // for IE

import {CustomForm, Loader, QuestionsPage} from './components';
import defaultConfig from './defaultConfig';
import {Reveal} from 'react-reveal';
import {initialState, reducer} from './reducer';
import {SummaryTable} from './components/SummaryTable';
const axios = require("axios").default;


export const App = ({pages, keyParam: key, privacyLink, agbLink, imprintLink, requestUrl, leadUrl, afterFormSuccessUrl, sendCustomerMailUrl, contact}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {currentPageIndex, lastPageIndex, responseArray, pending, success, showForm, leadPending, leadError} = state;

  const handleSelectAnswer = (questionIndex) => {
    const currentResponse = pages[currentPageIndex].questions[questionIndex];
    const responseArrayCopy = [...responseArray];


    if (responseArrayCopy.length < currentPageIndex) {
      responseArrayCopy.push(currentResponse);
    } else {
      responseArrayCopy[currentPageIndex] = currentResponse;
    }

    dispatch({type: 'SET_RESPONSE_ARRAY', payload: responseArrayCopy});

    if (currentPageIndex + 1 < pages.length) {
      dispatch({type: 'SET_CURRENT_PAGE_INDEX', payload: currentPageIndex + 1}); // go next automatically
    } else {
      sendSurvey();
    }
  };

  const sendSurvey = async () => {
    const resultObject = {
      public_key: key,
      answers: {
        bathroom_type: responseArray[3].answer,
        bathroom_size: responseArray[4].answer,
        renovation: responseArray[1].answer !== 'new' ? 1 : 0,
        bathtub: responseArray[5].answer === 'both' ||
        responseArray[5].answer === 'bathtub' ? 1 : 0,
        shower: responseArray[5].answer === 'both' ||
        responseArray[5].answer === 'shower' ? 1 : 0,
      },
    };

    dispatch({type: 'SEND_SURVEY_PENDING'});

    /*fetch(requestUrl, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(resultObject),
    }).*/
    axios(requestUrl, {
      method: "POST",
      mode: "no-cors",
      data: resultObject,
      url: requestUrl
    })
      .then((response) => {
        dispatch({type: 'SEND_SURVEY_SUCCESS', payload: response.data});
    }).catch((error) => {
      console.error(error);
    });
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const configUrl = params.get('configUrl');
  let config_id = configUrl.split("/iframe/")[1];
  if (!config_id) {
    config_id = configUrl.split("/bath/")[1];
  }

  const handleSubmitForm = async (formData) => {
    const finalResultObject = {
      public_key: key,
      config_id: config_id,
      answers: {
        property_type: responseArray[0].answer,
        property_status: responseArray[1].answer,
        person_number: responseArray[2].answer,
        bathroom_type: responseArray[3].answer,
        bathroom_size: responseArray[4].answer,
        bathroom_option: responseArray[5].answer,
        implementation_time: responseArray[6].answer,
        request_info_type: 'price',
        renovation: responseArray[1].answer !== 'new' ? 1 : 0,
        bathtub: responseArray[5].answer === 'both' ||
        responseArray[5].answer === 'bathtub' ? 1 : 0,
        shower: responseArray[5].answer === 'both' ||
        responseArray[5].answer === 'shower' ? 1 : 0,
      },
      contact: formData,
    };
    try {
      dispatch({type: 'SEND_LEAD_PENDING'});

      await axios(leadUrl, {
        method: "POST",
        mode: "no-cors",
        data: finalResultObject,
        url: leadUrl
      });


     axios(sendCustomerMailUrl, {
        method: "POST",
       headers: {
          'Content-Type': 'application/json',
       },
        data: finalResultObject,
        url: sendCustomerMailUrl
      });
/*
      promises.push(fetch(sendCustomerMailUrl, {
        mode: 'no-cors',
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(finalResultObject),
      }));*/


      try {
        if(window && window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
          window.ReactNativeWebView.postMessage(JSON.stringify({'redirect': ''}));
        }

        if (window && window.parent && window.parent.postMessage && afterFormSuccessUrl) {
          window.parent.postMessage({'redirect': afterFormSuccessUrl}, '*');
          // NOTE: We want to redirect immediately, and it should not happen that the user can send it again
          // so we never fire SEND_LEAD_SUCCESS
          // dispatch({type: 'SEND_LEAD_SUCCESS'});
        } else {
          alert('Formular abgeschickt.');
        }
      } catch(err) {
        console.log(err)
      }



    } catch (e) {
      dispatch({type: 'SEND_LEAD_ERROR'});
      console.error(e);
    }
  };

  const handleGoBack = () => {
    if (currentPageIndex + 1 === virtualPaginationSize) {
      // form
      dispatch({type: 'GO_BACK_FORM', payload: currentPageIndex - 1});
    } else if (currentPageIndex + 1 === virtualPaginationSize - 1) {
      // price table
      dispatch({type: 'GO_BACK_TABLE', payload: currentPageIndex - 1});
    } else {
      dispatch({type: 'SET_CURRENT_PAGE_INDEX', payload: currentPageIndex - 1});
    }
  };

  const renderProgressBar = () => {
    return (
        <div className="paginationContainer">
          <div className="paginationNumbering">
            {currentPageIndex + 1} von <span
              className="highlighted">{virtualPaginationSize}</span>
          </div>
          <div className="progressBarContainer">
            {currentPageIndex !== 0 &&
            <i className="pointer fas fa-arrow-left fa-2x"
               onClick={handleGoBack}/>}
            <div className="progress" style={{flex: 1}}>
              <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${((currentPageIndex + 1) * 100) /
                    virtualPaginationSize}%`,
                  }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
              />
            </div>
          </div>
        </div>
    );
  };

  // const renderCurrentPage = () => {
  //   const currentPage = pages[currentPageIndex];
  //
  //   const selectedQuestionFromPage = responseArray[currentPageIndex] && responseArray[currentPageIndex].answer;
  //
  //   console.log(currentPageIndex, lastPageIndex);
  //
  //   return (
  //     <Reveal
  //       effect={currentPageIndex >= lastPageIndex ? 'fadeInRight' : 'fadeInLeft'}
  //       effectOut={currentPageIndex >= lastPageIndex ? 'fadeOutLeft' : 'fadeOutRight'}
  //       key={currentPage.title}
  //     >
  //       <QuestionsPage
  //         questions={currentPage.questions}
  //         title={currentPage.title}
  //         infoBoxText={currentPage.infoBoxText}
  //         selectAnswer={handleSelectAnswer}
  //         selectedQuestionFromPage={selectedQuestionFromPage}
  //       />
  //     </Reveal>
  //   );
  // };

  const renderPages = () => {
    const content = pages.map((page, index) => {
      const selectedQuestionFromPage = responseArray[index] &&
          responseArray[index].answer;

      return (
          <div
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                visibility: currentPageIndex === index ? 'visible' : 'hidden',
                overflow: 'hidden',
              }}
              key={index}
          >
            <Reveal
                effect={currentPageIndex >= lastPageIndex ?
                    'fadeInRight' :
                    'fadeInLeft'}
                effectOut={currentPageIndex >= lastPageIndex ?
                    'fadeOutLeft' :
                    'fadeOutRight'}
                when={currentPageIndex === index}
            >
              <QuestionsPage
                  questions={page.questions}
                  title={page.title}
                  infoBoxText={page.infoBoxText}
                  selectAnswer={handleSelectAnswer}
                  selectedQuestionFromPage={selectedQuestionFromPage}
              />
            </Reveal>
          </div>
      );
    });

    return <div style={{
      position: 'relative',
      width: '100%',
      height: '480px',
    }}>{content}</div>;
  };

  const virtualPaginationSize = pages.length + 2; //includes the 'price table' and the form

  let content = <Loader/>;

  if (!pending) {
    if (showForm) {
      content = <CustomForm
          contact={contact}
          privacyLink={privacyLink}
          agbLink={agbLink}
          leadPending={leadPending}
          leadError={leadError}
          handleSubmitForm={handleSubmitForm}
      />;
    } else if (success) {
      content = <SummaryTable
          dispatch={dispatch}
          surveryResponseAnswers={state.surveryResponseAnswers}
      />;
    } else {
      content = <div className={"padding"}>{renderPages()}</div>;
    }
  }

  return (
      <div className="container bottom-bar">
        <>
          {content}
          <div>{renderProgressBar()}</div>
        </>
        <div className="footer">
          <a href={imprintLink || agbLink}
             target="_blank">Impressum </a>
          |
          <a href={privacyLink}
             target="_blank"> Datenschutzerklärung</a>
        </div>
      </div>
  );
};

export default () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const configUrl = params.get('configUrl');
  const [configuration, setConfiguration] = useState(null);
  const [key, setKey] = useState(null);

  useEffect(() => {
    (async () => {
      if (configUrl) {
        const result = await fetch(configUrl, {
          method: 'get',
          headers: {'Content-Type': 'application/json'},
        });
        const remoteConfiguration = await result.json();
        setConfiguration(remoteConfiguration);
        setKey(remoteConfiguration.api_key);
      } else {
        const configData = params.get('configData');
        if (configData) {
          const config = JSON.parse(configData);
          setConfiguration(config);
          setKey(config.api_key);
        }
        else {
          setConfiguration(defaultConfig);
        }

      }
    })();
  }, []);

  // Overwrite colors from remote
  useEffect(() => {
    if (configuration) {
      let root = document.documentElement;
      root.style.setProperty('--primary-color', configuration.colors.primary);
      root.style.setProperty('--secondary-color',
          configuration.colors.secondary);
      root.style.setProperty('--background-color',
          configuration.colors.background);
      root.style.setProperty('--highlight-color',
          configuration.colors.highlight);
      root.style.setProperty('--box-color', configuration.colors.box);
      root.style.setProperty('--font-color', configuration.colors.font);
    }
  }, [configuration]);

  if (!configuration) {
    return <Loader/>;
  }

  return (
      <App
          requestUrl={configuration.requestUrl}
          sendCustomerMailUrl={configuration.sendCustomerMailUrl}
          afterFormSuccessUrl={configuration.afterFormSuccessUrl}
          leadUrl={configuration.leadUrl}
          contact={configuration.contact}
          pages={configuration.pages}
          keyParam={key}
          privacyLink={configuration.privacyLink}
          agbLink={configuration.agbLink}
          imprintLink={configuration.imprintLink}
      />
  );
};
